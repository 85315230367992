// border-radius
.border-noround {
    border-radius: 0 !important;
}

.border-round {
    border-radius: var(--border-radius) !important;
}

.border-circle {
    border-radius: 50% !important;
}
.border-round-left {
    border-top-left-radius: var(--border-radius);
    border-bottom-left-radius: var(--border-radius);
}

.border-round-top {
    border-top-left-radius: var(--border-radius);
    border-top-right-radius: var(--border-radius);
}

.border-round-right {
    border-top-right-radius: var(--border-radius);
    border-bottom-right-radius: var(--border-radius);
}

.border-round-bottom {
    border-bottom-left-radius: var(--border-radius);
    border-bottom-right-radius: var(--border-radius);
}

// border-width

.border-none {
    border-width: 0px !important;
    border-style: none;
}

.border-1 {
    border-width: 1px !important;
    border-style: solid;
}

.border-2 {
    border-width: 2px !important;
    border-style: solid;
}

.border-3 {
    border-width: 3px !important;
    border-style: solid;
}

.border-top-none {
    border-top-width: 0px !important;
    border-top-style: none;
}

.border-top-1 {
    border-top-width: 1px !important;
    border-top-style: solid;
}

.border-top-2 {
    border-top-width: 2px !important;
    border-top-style: solid;
}

.border-top-3 {
    border-top-width: 3px !important;
    border-top-style: solid;
}

.border-right-none {
    border-right-width: 0px !important;
    border-right-style: none;
}

.border-right-1 {
    border-right-width: 1px !important;
    border-right-style: solid;
}

.border-right-2 {
    border-right-width: 2px !important;
    border-right-style: solid;
}

.border-right-3 {
    border-right-width: 3px !important;
    border-right-style: solid;
}

.border-left-none {
    border-left-width: 0px !important;
    border-left-style: none;
}

.border-left-1 {
    border-left-width: 1px !important;
    border-left-style: solid;
}

.border-left-2 {
    border-left-width: 2px !important;
    border-left-style: solid;
}

.border-left-3 {
    border-left-width: 3px !important;
    border-left-style: solid;
}

.border-bottom-none {
    border-bottom-width: 0px !important;
    border-bottom-style: none;
}

.border-bottom-1 {
    border-bottom-width: 1px !important;
    border-bottom-style: solid;
}

.border-bottom-2 {
    border-bottom-width: 2px !important;
    border-bottom-style: solid;
}

.border-bottom-3 {
    border-bottom-width: 3px !important;
    border-bottom-style: solid;
}

.border-x-none {
    border-left-width: 0px !important;
    border-left-style: none;
    border-right-width: 0px !important;
    border-right-style: none;
}

.border-x-1 {
    border-left-width: 1px !important;
    border-left-style: solid;
    border-right-width: 1px !important;
    border-right-style: solid;
}

.border-x-2 {
    border-left-width: 2px !important;
    border-left-style: solid;
    border-right-width: 2px !important;
    border-right-style: solid;
}

.border-x-3 {
    border-left-width: 3px !important;
    border-left-style: solid;
    border-right-width: 3px !important;
    border-right-style: solid;
}

.border-y-none {
    border-top-width: 0px !important;
    border-top-style: none;
    border-bottom-width: 0px !important;
    border-bottom-style: none;
}

.border-y-1 {
    border-top-width: 1px !important;
    border-top-style: solid;
    border-bottom-width: 1px !important;
    border-bottom-style: solid;
}

.border-y-2 {
    border-top-width: 2px !important;
    border-top-style: solid;
    border-bottom-width: 2px !important;
    border-bottom-style: solid;
}

.border-y-3 {
    border-top-width: 3px !important;
    border-top-style: solid;
    border-bottom-width: 3px !important;
    border-bottom-style: solid;
}

// border-style
.border-solid {
    border-style: solid !important;
}

.border-dashed {
    border-style: dashed !important;
}

.border-dotted {
    border-style: dotted !important;
}

.border-double {
    border-style: double !important;
}

// border-color
.border-surface-a {
    border-color: var(--surface-a) !important;
}
.border-surface-b {
    border-color: var(--surface-b) !important;
}
.border-surface-c {
    border-color: var(--surface-c) !important;
}
.border-default {
    border-color: var(--surface-c) !important;
}
.border-input {
    border-color: var(--color-border) !important;
}
.border-surface-d {
    border-color: var(--surface-d) !important;
}
.border-indigo {
    border-color: var(--indigo) !important;
}
.border-purple {
    border-color: var(--purple) !important;
}
.border-pink {
    border-color: var(--pink) !important;
}
.border-red {
    border-color: var(--red) !important;
}
.border-orange {
    border-color: var(--orange) !important;
}
.border-yellow {
    border-color: var(--yellow) !important;
}
.border-green {
    border-color: var(--green) !important;
}
.border-teal {
    border-color: var(--teal) !important;
}
.border-cyan {
    border-color: var(--cyan) !important;
}
.border-gray {
    border-color: var(--gray) !important;
}
.border-gray-dark {
    border-color: var(--gray-dark) !important;
}
.border-primary {
    border-color: var(--primary) !important;
}
.border-secondary {
    border-color: var(--secondary) !important;
}
.border-success {
    border-color: var(--success) !important;
}
.border-info {
    border-color: var(--info) !important;
}
.border-warning {
    border-color: var(--warning) !important;
}
.border-danger {
    border-color: var(--danger) !important;
}
.border-bottom-white{
   border-bottom-color: white!important;
}

.border-primary-gjs{
    border-color: var(--primary-gjs) !important;
}
.border-gjs{
    border-color: var(--border-gjs) !important;
}
