@keyframes pulse-blue {
    0% {
        transform: scale(0.9);
        box-shadow: 0 0 0 0 var(--surface-a);
    }

    70% {
        transform: scale(1);
        box-shadow: 0 0 0 10px rgba(255, 82, 82, 0);
    }

    100% {
        transform: scale(0.9);
        box-shadow: 0 0 0 0 rgba(255, 82, 82, 0);
    }
}

@keyframes pulse {
    0% {
        background-color: rgba(165, 165, 165, 0.1)
    }
    50% {
        background-color: rgba(165, 165, 165, 0.3)
    }
    100% {
        background-color: rgba(165, 165, 165, 0.1)
    }
}

.flicker-in-2 {
    -webkit-animation: flicker-in-2 1s ease-in infinite both;
    animation: flicker-in-2 1s ease-in infinite both;
}

/* ----------------------------------------------
 * Generated by Animista on 2021-4-1 11:30:22
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info.
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * @animation flicker-in-1
 * ----------------------------------------
 */
.new-icon{
    -webkit-animation: hide-show 1s ease-in infinite both;
    animation: hide-show 1s ease-in infinite both;
}
.removed{
    // transform: translateX(100vh);
    -webkit-animation: slide-out-left 0.5s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
	        animation: slide-out-left 0.5s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
}

.slide-out-left {
	-webkit-animation: slide-out-left 0.5s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
	        animation: slide-out-left 0.5s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
}
@-webkit-keyframes slide-out-left {
    0% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
      opacity: 1;
    }
    100% {
      -webkit-transform: translateX(-1000px);
              transform: translateX(-1000px);
      opacity: 0;
    }
  }
  @keyframes slide-out-left {
    0% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
      opacity: 1;
    }
    100% {
      -webkit-transform: translateX(-1000px);
              transform: translateX(-1000px);
      opacity: 0;
    }
  }

@keyframes hide-show {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}
@-webkit-keyframes flicker-in-2 {
    0% {
        opacity: 0;
    }
    10% {
        opacity: 0;
        -webkit-box-shadow: none;
        box-shadow: none;
    }
    10.1% {
        opacity: 1;
        -webkit-box-shadow: none;
        box-shadow: none;
    }
    10.2% {
        opacity: 0;
        -webkit-box-shadow: none;
        box-shadow: none;
    }
    20% {
        opacity: 0;
        -webkit-box-shadow: none;
        box-shadow: none;
    }
    20.1% {
        opacity: 1;
        -webkit-box-shadow: 0 0 30px rgba(255, 255, 255, 0.25);
        box-shadow: 0 0 30px rgba(255, 255, 255, 0.25);
    }
    20.6% {
        opacity: 0;
        -webkit-box-shadow: none;
        box-shadow: none;
    }
    30% {
        opacity: 0;
        -webkit-box-shadow: none;
        box-shadow: none;
    }
    30.1% {
        opacity: 1;
        -webkit-box-shadow: 0 0 30px rgba(255, 255, 255, 0.45), 0 0 60px rgba(255, 255, 255, 0.25);
        box-shadow: 0 0 30px rgba(255, 255, 255, 0.45), 0 0 60px rgba(255, 255, 255, 0.25);
    }
    30.5% {
        opacity: 1;
        -webkit-box-shadow: 0 0 30px rgba(255, 255, 255, 0.45), 0 0 60px rgba(255, 255, 255, 0.25);
        box-shadow: 0 0 30px rgba(255, 255, 255, 0.45), 0 0 60px rgba(255, 255, 255, 0.25);
    }
    30.6% {
        opacity: 0;
        -webkit-box-shadow: none;
        box-shadow: none;
    }
    45% {
        opacity: 0;
        -webkit-box-shadow: none;
        box-shadow: none;
    }
    45.1% {
        opacity: 1;
        -webkit-box-shadow: 0 0 30px rgba(255, 255, 255, 0.45), 0 0 60px rgba(255, 255, 255, 0.25);
        box-shadow: 0 0 30px rgba(255, 255, 255, 0.45), 0 0 60px rgba(255, 255, 255, 0.25);
    }
    50% {
        opacity: 1;
        -webkit-box-shadow: 0 0 30px rgba(255, 255, 255, 0.45), 0 0 60px rgba(255, 255, 255, 0.25);
        box-shadow: 0 0 30px rgba(255, 255, 255, 0.45), 0 0 60px rgba(255, 255, 255, 0.25);
    }
    55% {
        opacity: 1;
        -webkit-box-shadow: 0 0 30px rgba(255, 255, 255, 0.45), 0 0 60px rgba(255, 255, 255, 0.25);
        box-shadow: 0 0 30px rgba(255, 255, 255, 0.45), 0 0 60px rgba(255, 255, 255, 0.25);
    }
    55.1% {
        opacity: 0;
        -webkit-box-shadow: none;
        box-shadow: none;
    }
    57% {
        opacity: 0;
        -webkit-box-shadow: none;
        box-shadow: none;
    }
    57.1% {
        opacity: 1;
        -webkit-box-shadow: 0 0 30px rgba(255, 255, 255, 0.55), 0 0 60px rgba(255, 255, 255, 0.3);
        box-shadow: 0 0 30px rgba(255, 255, 255, 0.55), 0 0 60px rgba(255, 255, 255, 0.3);
    }
    60% {
        opacity: 1;
        -webkit-box-shadow: 0 0 30px rgba(255, 255, 255, 0.55), 0 0 60px rgba(255, 255, 255, 0.3);
        box-shadow: 0 0 30px rgba(255, 255, 255, 0.55), 0 0 60px rgba(255, 255, 255, 0.3);
    }
    60.1% {
        opacity: 0;
        -webkit-box-shadow: none;
        box-shadow: none;
    }
    65% {
        opacity: 0;
        -webkit-box-shadow: none;
        box-shadow: none;
    }
    65.1% {
        opacity: 1;
        -webkit-box-shadow: 0 0 30px rgba(255, 255, 255, 0.55), 0 0 60px rgba(255, 255, 255, 0.3),
            0 0 100px rgba(255, 255, 255, 0.1);
        box-shadow: 0 0 30px rgba(255, 255, 255, 0.55), 0 0 60px rgba(255, 255, 255, 0.3),
            0 0 100px rgba(255, 255, 255, 0.1);
    }
    75% {
        opacity: 1;
        -webkit-box-shadow: 0 0 30px rgba(255, 255, 255, 0.55), 0 0 60px rgba(255, 255, 255, 0.3),
            0 0 100px rgba(255, 255, 255, 0.1);
        box-shadow: 0 0 30px rgba(255, 255, 255, 0.55), 0 0 60px rgba(255, 255, 255, 0.3),
            0 0 100px rgba(255, 255, 255, 0.1);
    }
    75.1% {
        opacity: 0;
        -webkit-box-shadow: none;
        box-shadow: none;
    }
    77% {
        opacity: 0;
        -webkit-box-shadow: none;
        box-shadow: none;
    }
    77.1% {
        opacity: 1;
        -webkit-box-shadow: 0 0 30px rgba(255, 255, 255, 0.6), 0 0 60px rgba(255, 255, 255, 0.4),
            0 0 110px rgba(255, 255, 255, 0.2), 0 0 100px rgba(255, 255, 255, 0.1);
        box-shadow: 0 0 30px rgba(255, 255, 255, 0.6), 0 0 60px rgba(255, 255, 255, 0.4),
            0 0 110px rgba(255, 255, 255, 0.2), 0 0 100px rgba(255, 255, 255, 0.1);
    }
    85% {
        opacity: 1;
        -webkit-box-shadow: 0 0 30px rgba(255, 255, 255, 0.6), 0 0 60px rgba(255, 255, 255, 0.4),
            0 0 110px rgba(255, 255, 255, 0.2), 0 0 100px rgba(255, 255, 255, 0.1);
        box-shadow: 0 0 30px rgba(255, 255, 255, 0.6), 0 0 60px rgba(255, 255, 255, 0.4),
            0 0 110px rgba(255, 255, 255, 0.2), 0 0 100px rgba(255, 255, 255, 0.1);
    }
    85.1% {
        opacity: 0;
        -webkit-box-shadow: none;
        box-shadow: none;
    }
    86% {
        opacity: 0;
        -webkit-box-shadow: none;
        box-shadow: none;
    }
    86.1% {
        opacity: 1;
        -webkit-box-shadow: 0 0 30px rgba(255, 255, 255, 0.6), 0 0 60px rgba(255, 255, 255, 0.45),
            0 0 110px rgba(255, 255, 255, 0.25), 0 0 100px rgba(255, 255, 255, 0.1);
        box-shadow: 0 0 30px rgba(255, 255, 255, 0.6), 0 0 60px rgba(255, 255, 255, 0.45),
            0 0 110px rgba(255, 255, 255, 0.25), 0 0 100px rgba(255, 255, 255, 0.1);
    }
    100% {
        opacity: 1;
        -webkit-box-shadow: 0 0 30px rgba(255, 255, 255, 0.6), 0 0 60px rgba(255, 255, 255, 0.45),
            0 0 110px rgba(255, 255, 255, 0.25), 0 0 100px rgba(255, 255, 255, 0.1);
        box-shadow: 0 0 30px rgba(255, 255, 255, 0.6), 0 0 60px rgba(255, 255, 255, 0.45),
            0 0 110px rgba(255, 255, 255, 0.25), 0 0 100px rgba(255, 255, 255, 0.1);
    }
}
@keyframes flicker-in-2 {
    0% {
        opacity: 0;
    }
    10% {
        opacity: 0;
        -webkit-box-shadow: none;
        box-shadow: none;
    }
    10.1% {
        opacity: 1;
        -webkit-box-shadow: none;
        box-shadow: none;
    }
    10.2% {
        opacity: 0;
        -webkit-box-shadow: none;
        box-shadow: none;
    }
    20% {
        opacity: 0;
        -webkit-box-shadow: none;
        box-shadow: none;
    }
    20.1% {
        opacity: 1;
        -webkit-box-shadow: 0 0 30px rgba(255, 255, 255, 0.25);
        box-shadow: 0 0 30px rgba(255, 255, 255, 0.25);
    }
    20.6% {
        opacity: 0;
        -webkit-box-shadow: none;
        box-shadow: none;
    }
    30% {
        opacity: 0;
        -webkit-box-shadow: none;
        box-shadow: none;
    }
    30.1% {
        opacity: 1;
        -webkit-box-shadow: 0 0 30px rgba(255, 255, 255, 0.45), 0 0 60px rgba(255, 255, 255, 0.25);
        box-shadow: 0 0 30px rgba(255, 255, 255, 0.45), 0 0 60px rgba(255, 255, 255, 0.25);
    }
    30.5% {
        opacity: 1;
        -webkit-box-shadow: 0 0 30px rgba(255, 255, 255, 0.45), 0 0 60px rgba(255, 255, 255, 0.25);
        box-shadow: 0 0 30px rgba(255, 255, 255, 0.45), 0 0 60px rgba(255, 255, 255, 0.25);
    }
    30.6% {
        opacity: 0;
        -webkit-box-shadow: none;
        box-shadow: none;
    }
    45% {
        opacity: 0;
        -webkit-box-shadow: none;
        box-shadow: none;
    }
    45.1% {
        opacity: 1;
        -webkit-box-shadow: 0 0 30px rgba(255, 255, 255, 0.45), 0 0 60px rgba(255, 255, 255, 0.25);
        box-shadow: 0 0 30px rgba(255, 255, 255, 0.45), 0 0 60px rgba(255, 255, 255, 0.25);
    }
    50% {
        opacity: 1;
        -webkit-box-shadow: 0 0 30px rgba(255, 255, 255, 0.45), 0 0 60px rgba(255, 255, 255, 0.25);
        box-shadow: 0 0 30px rgba(255, 255, 255, 0.45), 0 0 60px rgba(255, 255, 255, 0.25);
    }
    55% {
        opacity: 1;
        -webkit-box-shadow: 0 0 30px rgba(255, 255, 255, 0.45), 0 0 60px rgba(255, 255, 255, 0.25);
        box-shadow: 0 0 30px rgba(255, 255, 255, 0.45), 0 0 60px rgba(255, 255, 255, 0.25);
    }
    55.1% {
        opacity: 0;
        -webkit-box-shadow: none;
        box-shadow: none;
    }
    57% {
        opacity: 0;
        -webkit-box-shadow: none;
        box-shadow: none;
    }
    57.1% {
        opacity: 1;
        -webkit-box-shadow: 0 0 30px rgba(255, 255, 255, 0.55), 0 0 60px rgba(255, 255, 255, 0.3);
        box-shadow: 0 0 30px rgba(255, 255, 255, 0.55), 0 0 60px rgba(255, 255, 255, 0.3);
    }
    60% {
        opacity: 1;
        -webkit-box-shadow: 0 0 30px rgba(255, 255, 255, 0.55), 0 0 60px rgba(255, 255, 255, 0.3);
        box-shadow: 0 0 30px rgba(255, 255, 255, 0.55), 0 0 60px rgba(255, 255, 255, 0.3);
    }
    60.1% {
        opacity: 0;
        -webkit-box-shadow: none;
        box-shadow: none;
    }
    65% {
        opacity: 0;
        -webkit-box-shadow: none;
        box-shadow: none;
    }
    65.1% {
        opacity: 1;
        -webkit-box-shadow: 0 0 30px rgba(255, 255, 255, 0.55), 0 0 60px rgba(255, 255, 255, 0.3),
            0 0 100px rgba(255, 255, 255, 0.1);
        box-shadow: 0 0 30px rgba(255, 255, 255, 0.55), 0 0 60px rgba(255, 255, 255, 0.3),
            0 0 100px rgba(255, 255, 255, 0.1);
    }
    75% {
        opacity: 1;
        -webkit-box-shadow: 0 0 30px rgba(255, 255, 255, 0.55), 0 0 60px rgba(255, 255, 255, 0.3),
            0 0 100px rgba(255, 255, 255, 0.1);
        box-shadow: 0 0 30px rgba(255, 255, 255, 0.55), 0 0 60px rgba(255, 255, 255, 0.3),
            0 0 100px rgba(255, 255, 255, 0.1);
    }
    75.1% {
        opacity: 0;
        -webkit-box-shadow: none;
        box-shadow: none;
    }
    77% {
        opacity: 0;
        -webkit-box-shadow: none;
        box-shadow: none;
    }
    77.1% {
        opacity: 1;
        -webkit-box-shadow: 0 0 30px rgba(255, 255, 255, 0.6), 0 0 60px rgba(255, 255, 255, 0.4),
            0 0 110px rgba(255, 255, 255, 0.2), 0 0 100px rgba(255, 255, 255, 0.1);
        box-shadow: 0 0 30px rgba(255, 255, 255, 0.6), 0 0 60px rgba(255, 255, 255, 0.4),
            0 0 110px rgba(255, 255, 255, 0.2), 0 0 100px rgba(255, 255, 255, 0.1);
    }
    85% {
        opacity: 1;
        -webkit-box-shadow: 0 0 30px rgba(255, 255, 255, 0.6), 0 0 60px rgba(255, 255, 255, 0.4),
            0 0 110px rgba(255, 255, 255, 0.2), 0 0 100px rgba(255, 255, 255, 0.1);
        box-shadow: 0 0 30px rgba(255, 255, 255, 0.6), 0 0 60px rgba(255, 255, 255, 0.4),
            0 0 110px rgba(255, 255, 255, 0.2), 0 0 100px rgba(255, 255, 255, 0.1);
    }
    85.1% {
        opacity: 0;
        -webkit-box-shadow: none;
        box-shadow: none;
    }
    86% {
        opacity: 0;
        -webkit-box-shadow: none;
        box-shadow: none;
    }
    86.1% {
        opacity: 1;
        -webkit-box-shadow: 0 0 30px rgba(255, 255, 255, 0.6), 0 0 60px rgba(255, 255, 255, 0.45),
            0 0 110px rgba(255, 255, 255, 0.25), 0 0 100px rgba(255, 255, 255, 0.1);
        box-shadow: 0 0 30px rgba(255, 255, 255, 0.6), 0 0 60px rgba(255, 255, 255, 0.45),
            0 0 110px rgba(255, 255, 255, 0.25), 0 0 100px rgba(255, 255, 255, 0.1);
    }
    100% {
        opacity: 1;
        -webkit-box-shadow: 0 0 30px rgba(255, 255, 255, 0.6), 0 0 60px rgba(255, 255, 255, 0.45),
            0 0 110px rgba(255, 255, 255, 0.25), 0 0 100px rgba(255, 255, 255, 0.1);
        box-shadow: 0 0 30px rgba(255, 255, 255, 0.6), 0 0 60px rgba(255, 255, 255, 0.45),
            0 0 110px rgba(255, 255, 255, 0.25), 0 0 100px rgba(255, 255, 255, 0.1);
    }
}
