html {
    height: 100%;
    font-size: $fontSize;
}

body {
    margin: 0px;
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    background-color: var(--background-app);
    font-family: var(--font-family);
    font-weight: normal;
    color: var(--text-color);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

a {
    text-decoration: none;
    color: var(--primary-color);
}

.layout-theme-light {
    background-color: #edf1f5;
}
