.layout-topbar {
    background-color: var(--surface-a);
    padding: 0 2rem;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 997;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.25);
    display: flex;
    align-items: center;

    .topbar-menu {
        list-style-type: none;
        margin: 0;
        padding: 0;
    }

    .flag-icon {
        .p-menuitem-text {
            display: flex;
        }

        >a {
            span:first-child {
                font-size: 1.25rem;
                display: flex;
            }
        }
    }
}