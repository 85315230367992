$colorTextMenu: var(--text-color);
$colorIconMenu: var(--text-color);

@mixin colorMenu($color, $font) {
    color: $color !important;
    font-weight: $font !important;
}

.menu-customer {
    .p-menubar {
        height: var(--height-header-footer);
        padding: 0;
        background-color: var(--surface-a);

        .p-submenu-list {
            right: 0;
        }

        .logo-page {
            width: 13.5rem;
            height: auto;
        }

        .logo-page-contract {
            height: 50px;
        }

        img {
            vertical-align: bottom;
        }

        .menu-text {
            @include colorMenu($colorTextMenu, 600);
        }

        .p-menubar-root-list > .p-menuitem > .p-menuitem-link .p-menuitem-text {
            @include colorMenu($colorTextMenu, 600);
        }

        .p-menubar-root-list > .p-menuitem > .p-menuitem-link .p-menuitem-text:hover {
            @include colorMenu($colorTextMenu, 600);
        }

        .p-menubar-root-list > .p-menuitem > .p-menuitem-link:hover {
            @include colorMenu($colorTextMenu, 600);
        }

        .p-menubar-root-list > .p-menuitem > .p-menuitem-link .p-menuitem-text:focus {
            @include colorMenu($colorTextMenu, 600);
        }

        .p-menubar-root-list > .p-menuitem > .p-menuitem-link .p-menuitem-text:active {
            @include colorMenu($colorTextMenu, 600);
        }

        .p-menubar-root-list > .p-menuitem > .p-menuitem-link {
            @include colorMenu($colorTextMenu, 600);
            background: none !important;
        }

        .p-menubar-root-list .p-menuitem .p-menuitem-link .p-menuitem-icon {
            @include colorMenu($colorTextMenu, 500);
        }

        .p-menubar-root-list .p-menuitem .p-menuitem-link .p-submenu-icon {
            color: $colorTextMenu !important;
        }

        .p-menubar-root-list .p-menuitem .p-submenu-list .p-menuitem-link .p-menuitem-icon {
            @include colorMenu($colorIconMenu, 500);
        }

        .p-menubar-root-list > .text-white > .p-menuitem-link .p-menuitem-text {
            color: #fff !important;
        }
    }

    .p-connected-overlay-enter-done {
        display: block;
    }

    .p-connected-overlay-exit {
        display: none;
    }
}
.menu-home {
    .item-link {
        color: #212529;
    }

    .login-item {
        .menu-item__link {
            border-radius: 4px;
            background: #dc3545 !important;
            border: 1px solid #dc3545;
            color: #ffffff;
            height: 33px;
        }
    }

    .p-menubar-root-list {
        width: 100%;
    }
}

.p-menubar-start {
    height: var(--height-header-footer);

    .menu-start {
        height: 100%;
        display: flex;
        align-items: center;
    }
}

.menu-wrapper {
    flex-wrap: wrap;
    width: calc(100% - 252.78px);
    display: flex;
    align-items: center;
}

.angle-down-custom {
    padding-top: 0.3rem;
}

.menu-item {
    position: relative;
    height: var(--height-header-footer);
    display: flex;
    align-items: center;

    .menu-item__label {
        margin: 0 0.5rem;
    }

    .menu-item__link {
        position: relative;
        color: var(--text-color);
        background: none !important;
        transition: border 0.15s;
        text-decoration: none;
        padding: 0 0.5rem;
        height: 75%;
        width: 100%;
        border: 2px solid transparent;
        border-radius: 4px;
        display: flex;
        align-items: center;
        font-weight: 600 !important;
        cursor: pointer;
    }

    .sub-menu {
        display: none;
        position: absolute;
        top: 100%;
        left: 0;
        min-width: fit-content;
        width: auto;
        white-space: nowrap;
        padding: 0.5rem 0;
        background: #ffffff;
        border: 1px solid rgba(0, 0, 0, 0.15);
        box-shadow: none;
        z-index: 10;

        .user-demo-info {
            border-bottom: 1px solid #ccc;

            .sub-menu-item__link {
                cursor: default !important;
            }

            &:hover {
                background-color: transparent !important;
            }
        }

        .sub-menu-item {
            position: relative;

            .sub-menu-item__link {
                cursor: pointer;
                display: flex;
                align-items: center;
                text-decoration: none;
                padding: 0.75rem 1rem;
                color: #212529;
                transition: border 0.15s;
            }

            .sub-sub-menu {
                display: none;
                position: absolute;
                left: calc(100% + 0.5px);
                top: 0;
                width: auto !important;
                white-space: nowrap;
                padding: 0.5rem 0;
                background: #ffffff;
                border: 1px solid rgba(0, 0, 0, 0.15);

                .sub-sub-menu-item {
                    .sub-sub-menu-item__link {
                        cursor: pointer;
                        display: flex;
                        align-items: center;
                        text-decoration: none;
                        padding: 0.75rem 1rem;
                        color: #212529;
                        border-radius: 0;
                        transition: border 0.15s;
                    }

                    &:hover {
                        background: #e9ecef;
                    }
                }
            }

            &:hover {
                background: #e9ecef;

                .sub-sub-menu {
                    display: block;
                }
            }
        }
    }

    .right-0 {
        left: auto;
        right: 0 !important;
    }

    &:hover {
        .sub-menu {
            display: block;
        }

        .menu-item__link {
            border-color: #fff;
        }
    }
}

// Tablet
@media screen and (max-width: 1174px) and (min-width: $lg) {
    .menu-item__sub {
        left: 0;

        .menu-item__sub-item {
            position: relative;

            .menu-item__sub-link {
                cursor: pointer;
                padding: 0.75rem 1rem;
                color: #212529;
                border-radius: 0;
                display: flex;
                align-items: center;
                text-decoration: none;
                overflow: hidden;
            }

            &:hover {
                background-color: #e9ecef;
            }
        }
    }
}

// Mobile
.mobile-item__nav--mr {
    margin-right: 0.5rem;
}

.sub-nav__container {
    padding: 0.5rem 0;
    border: 1px solid #dee2e6;
    background: #fff;
    color: #212529;
    margin-bottom: 0;
    border-top: 0;

    .sub-nav__sub-item {
        .sub-nav__sub-link {
            cursor: pointer;
            padding: 0.75rem 1rem;
            color: #212529;
            border-radius: 0;
            transition: border 0.15s;
            display: flex;
            align-items: center;

            &:hover {
                background-color: #e9ecef;
            }
        }
    }
}

.display-mobile {
    display: none;
}

.sidebar-menu {
    white-space: nowrap;

    .p-accordion-content {
        padding: 0 !important;
    }

    .sidebar-link {
        padding: 0.75rem 1rem;
        color: #212529;
        display: flex;
        align-items: center;
        cursor: pointer;

        &:hover {
            background: #e9ecef;
        }
    }

    .sidebar-sub-menu {
        padding: 0;
        cursor: pointer;

        .p-accordion-header-link {
            border: none;
            padding: 0.75rem 1rem;
            background-color: transparent;
            font-weight: normal;
        }

        .p-accordion-content {
            border: none;
        }

        .sidebar-link {
            padding-left: 2.5rem;
        }
    }

    &:first-child {
        border-top-right-radius: 4px;
        border-top-left-radius: 4px;
    }

    &:last-child {
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 4px;
    }

    .sidebar-menu__header {
        .sidebar-menu__link {
            padding: 1rem 1.25rem;
            border: 1px solid #dee2e6;
            color: #212529;
            background: #efefef;
            font-weight: 600;
            transition: border 0.15s;
            cursor: pointer;
            display: flex;
            align-items: center;
            user-select: none;
            position: relative;
            text-decoration: none;
        }
    }

    .sidebar-menu__content {
        border: 1px solid #dee2e6;
        background: #fff;
        color: #212529;
        padding: 0.5rem 0;

        .sidebar-menu__content--link {
            cursor: pointer;
            padding: 0.75rem 1rem;
            color: #212529;
            border-radius: 0;
            transition: border 0.15s;
            display: flex;
            align-items: center;

            &:hover {
                background-color: #e9ecef;
            }
        }
    }
}

//Mobile
@media screen and (max-width: $lg) {
    .menu-wrapper {
        display: none;
    }

    .display-mobile {
        display: block;
    }

    .none-display-mobile {
        display: none !important;
    }
}
