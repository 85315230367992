.p-mb-n1 {
    margin-bottom: -0.5rem !important;
}
.p-pb-n1 {
    padding-bottom: -0.5rem !important;
}

.p-mt-n1 {
    margin-top: -0.5rem !important;
}
.p-pt-n1 {
    padding-top: -0.5rem !important;
}
.p-mt-n3 {
    margin-top: -1rem !important;
}
.p-pt-n3 {
    padding-top: -1rem !important;
}
