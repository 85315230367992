$menu: "menu-notify";


.MenubarRight {

    position: absolute;
    top: 0;
    right: 0;

    .text-right {
        text-align: right;
    }

    .p-tag {
        padding-top: 0.1rem !important;

        .p-tag-value {
            font-size: 0.65rem;
        }
    }

    .icon-notify {
        font-size: 1.4rem;
        margin-right: 1.1rem !important;
    }

    .icon-custom {
        font-weight: medium;
    }

    .menu-link:not(.p-disabled):hover {
        background: none;
    }

    .#{$menu} {
        z-index: 1000;
    }

    .#{$menu}__header {
        border-bottom: 1px solid var(--surface-d);
    }

    .#{$menu}__ul {
        width: 23rem !important;
    }

    .#{$menu}__ul--more {
        right: 0.5rem;
        top: 0;
        font-weight: bold;
        color: black !important;
    }

    .#{$menu}__li {
        position: relative;
        border-bottom: 1px solid var(--surface-d) !important;

        &:last-of-type {
            border: none !important;
        }

        &:first-of-type {
            border-top: 1px solid var(--surface-d) !important;
        }

        transition: transform 0.5s 0s;

        &::after {
            content: "";
            background-color: var(--primary);
            width: 7px;
            height: 7px;
            border-radius: 50%;
            position: absolute;
            top: 50%;
            right: 10px;
        }
    }

    .#{$menu}__li--active {
        &::after {
            background: none;
        }

        .#{$menu}__item {
            color: #73818f !important;
        }
    }

    .#{$menu}__li--avatar {
        border-radius: 50%;
    }

    .#{$menu}__li--icon {
        position: absolute;
        right: -4px;
        bottom: -4px;
    }

    .#{$menu}__date {
        color: var(--primary) !important;
    }

    .#{$menu}__date--active {
        color: #73818f !important;
    }

    .#{$menu}__item--more {
        z-index: 999;
        right: 1.8rem;
        top: 34%;
        align-items: center;
        background-color: var(--bluegray-200);
        border-color: var(--bluegray-200);
        font-weight: bold;
        color: black !important;
    }

    .menuSeeMore {
        z-index: 1001;
        top: 60px;
        right: 5px;
    }

    .menuUlMore {
        z-index: 1001;
        top: 42px;
        right: 0px;
    }

    .flag-icon {
        .p-dropdown-label {
            font-size: 1.25rem;
            padding-right: 0;
            display: flex;
        }

        .p-dropdown-trigger-icon {
            font-size: 0.85rem;
            color: #000;
        }
    }
}

.user_info {
    display: flex;
    flex-direction: column;
}

.user_info-mobile {
    display: none;
    flex-direction: column;
    padding: 0.75rem 1rem;
    color: #212529;
    border-bottom: 1px solid #ccc;
}

@media screen and (max-width: $lg) {
    .user_info-mobile {
        display: flex;
    }

    .user_info {
        display: none;
    }
}