.layout-content {
    .content-section {
        min-height: var(--min-height-content);
        padding: 0 1.6rem 0.1rem 1.6rem !important;
    }
}

.p-menubar {
    .p-menubar-root-list > .p-menuitem > .p-menuitem-link:focus {
        outline: 0 none;
        outline-offset: 0;
        box-shadow: inset 0 0 0 0.15rem #ecece2;
    }
}

.p-chips {
    .p-chips-multiple-container {
        .p-chips-token {
            margin-top: 0.2rem;
        }
    }
}

.p-submenu-list:not(.menu-notify__ul) {
    width: auto !important;
    white-space: nowrap;
}

.p-filter-column {
    background-color: #ffffff !important;
}

// css nút dropdow
.p-paginator {
    padding: 0;

    .p-dropdown {
        margin-right: 0.5rem;

        div {
            span {
                color: var(--primary) !important;
            }
        }
    }
}

.link-color {
    color: var(--link-color);
}

@mixin block-ellipsis($row) {
    display: -webkit-box;
    -webkit-line-clamp: $row;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.max-line-3 {
    @include block-ellipsis(3);

    span {
        @include block-ellipsis(3);
    }
}

.max-line-2 {
    @include block-ellipsis(2);
}

// table dài tự động xuống dòng
.table-word-break {
    .p-datatable-tbody {
        word-break: break-word !important;
    }

    .p-treetable-tbody {
        word-break: break-word !important;
    }
}

.break-word {
    word-break: break-word !important;
}

.p-toolbar {
    padding-top: 0.5rem;
    padding-bottom: 0rem;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    background: #fff;
    // border-left:none ;
    // border-right:none ;
    // border-radius: 0;
}

.p-datatable {
    .p-checkbox {
        .p-checkbox-box.p-highlight {
            border-color: var(--primary);
        }
    }
}

.icon-medium {
    font-size: 1.4em;
}

.icon-danh-so {
    font-size: 1.7em;
}

img.icon-medium {
    width: 20px;
    vertical-align: sub;
}

.p-tag-secondary {
    background-color: var(--secondary);
}

.pointer {
    cursor: pointer !important;
}

.textarea-custom {
    overflow-y: scroll !important;
    max-height: 6rem !important;
}

.p-tag.p-tag-xl {
    font-size: 1.5rem;
    min-width: 3rem;
    height: 3rem;
    line-height: 3rem;
}

.p-dialog-mask {
    z-index: 997 !important;
}

@mixin p-accordion-color($bg, $color) {
    background: $bg;
    border-color: $bg;
    color: $color;
}


// css tabel
.label-bold {
    font-weight: 600;
}
.label-bolder {
    font-weight: bold;
}

.stt-table {
    min-width: 3.5rem !important;
    width: 3.5rem !important;
    justify-content: center;
    .p-column-header-content {
        justify-content: center;
    }
}

.stt-table-max {
    max-width: 4rem !important;
    justify-content: center;
    .p-column-header-content {
        justify-content: center;
    }
}
.stt-table-max-5 {
    max-width: 5.5rem !important;
    justify-content: center;
    .p-column-header-content {
        justify-content: center;
    }
}

.action-table,
.action-tree-table {
    min-width: 8rem !important;
    width: 8rem !important;
    justify-content: center;
}

.header-center {
    justify-content: center;
    .p-column-header-content {
        justify-content: center;
    }
}

.p-datatable {
    .p-datatable-thead tr th {
        background-color: var(--thead) !important;
    }

    .p-datatable-tbody > tr.p-highlight {
        background-color: var(--tbody) !important;
        color: var(--text-color);
    }
}

svg {
    // vertical-align: bottom;
    vertical-align: sub;
}

.cursor-help {
    cursor: help;
}

.p-tree-selectable {
    max-height: 55vh;
    overflow-y: auto;
}

.p-disabled:not(button, .p-steps-item, .p-checkbox-box) {
    opacity: 1 !important;
    background-color: var(--surface-c) !important;
}

.p-datatable-tbody {
    .p-disabled {
        opacity: 0.6 !important;
        background-color: none !important;
    }
}

// bỏ margin scroll table
.p-datatable-scrollable-header-box,
.p-treetable-scrollable-header-box {
    margin-right: 0 !important;
}

.relative {
    position: relative;
}

.absolute {
    position: absolute;
}

.bottom-full{
    bottom:100%;
}

.top-full{
    top:100%;
}

.box-disabled {
    font-size: 1rem;
    color: #495057;
    border: 1px solid #ced4da;
    padding: 0.5rem 0.75rem;
    transition:
        background-color 0.15s,
        border-color 0.15s,
        box-shadow 0.15s;
    appearance: none;
    border-radius: 4px;
    overflow: auto;
    opacity: 1 !important;
    background-color: var(--surface-c);
    // cursor: no-drop !important;
}

.nowrap-text {
    white-space: nowrap;
}

.content-info-child {
    border-right: thin solid var(--surface-c);
    border-left: thin solid var(--surface-c);
    border-top: thin solid var(--surface-c);
    padding: 1rem;

    &:last-child {
        border-bottom: thin solid var(--surface-c);
    }
}

.overflow-hidden {
    overflow: hidden !important;
}

.overflow-visible {
    overflow: visible;
}

.border-sign-view {
    border: 1px solid rgb(170, 170, 170);
}

.input-scroll > ul.p-inputtext {
    overflow-y: auto !important;
    max-height: 7rem;
}

.view-template-document {
    max-height: 100%;
    overflow-y: auto !important;
    text-overflow: hidden !important;
}

.fixed {
    position: fixed;
}

.dialog-no-scroll {
    .p-dialog-content {
        overflow-x: inherit !important;
    }
}

.no-border {
    border: none;
}

.overflow-y-auto {
    overflow-y: auto !important;
}

.overflow-x-auto {
    overflow-x: auto !important;
}

.p-tooltip {
    word-break: break-word;
    max-width: 100vw;
}

.position-center {
    top: 50%;
    right: 50%;
    -webkit-transform: translate(50%, -50%);
    transform: translate(50%, -50%);
    position: absolute;
}

.country-item-value {
    max-width: 80vw;
}

.p-toast.p-toast-top-right,
.p-toast.p-toast-top-left {
    top: 100px;
}

.p-scrolltop {
    bottom: 44px;
    opacity: 0.5;
}

.text-hover:hover {
    cursor: pointer !important;
    text-decoration: underline !important;
}

.PageProgress {
    z-index: 1003 !important;
}
.customMultiSelect {
    background-color: transparent !important;
    color: black !important;
}
.customMultiSelect:focus {
    box-shadow: none !important;
}
.customMultiSelect .p-checkbox-box.p-highlight {
    background-color: #007bff !important;
    border-color: #007bff !important;
}
.exportCustom {
    z-index: 999 !important;
}
.whiteColor .p-multiselect-label {
    color: white !important;
}
.custom-link {
    cursor: pointer;
    color: #007bff;
    background: transparent;
    border: transparent;
    &:hover {
        background: transparent;
        color: #0069d9;
        border-color: transparent;
        text-decoration: underline;
    }
}
.customCenterTextTable .p-column-header-content {
    display: block !important;
    width: 100% !important;
    text-align: center !important;
}
.customDisabled .p-disabled:not(button, .p-steps-item) {
    opacity: 0.65 !important;
    background-color: #007bff !important;
}

.accordionCustomIn {
    max-height: 1000px !important;
    transition: max-height 1s ease-in-out;
    overflow: hidden;
}
.accordionCustomOut {
    max-height: 0 !important;
    transition: max-height 0.45s cubic-bezier(0, 1, 0, 1);
    overflow: hidden;
}
.view-pdf {
    .p-dialog-content {
        overflow: auto;
    }
}
.p-fileupload-basic {
    .p-disabled {
        opacity: 0.65 !important;
        background-color: var(--primary) !important;
    }
}

// chỉnh lại table khi fix cứng cột bên phải có thuốc tính scrollable
.p-datatable-scrollable.p-datatable-gridlines {
    table {
        border: solid #dee2e6;
        border-width: 0 0px 1px 0;
    }
    .p-datatable-thead {
        th {
            border-width: 1px 0 1px 1px !important;
        }
    }
    .p-datatable-tbody {
        td {
            border-width: 1px 0px 0 1px !important;
        }
        td:last-child {
            border-right-width: 1px !important;
        }
    }
}

.hr--18r6 {
    height: 18.6rem;
}
.sr-only {
    display: none;
}
.object-contain {
    object-fit: contain;
}

.object-right {
    object-position: right;
}

input::placeholder, textarea::placeholder, .p-placeholder{
    font-size: 0.929rem !important;
    // font-style: italic!important;
}
.p-multiselect-label{
    white-space: normal;
    max-height: 11rem;
    overflow: auto;
}

.p-multiselect.p-multiselect-chip .p-multiselect-token{
    margin-bottom: 0.25rem;
}
