.MenubarDemoMobile,
.MenubarHomeMobile {
    display: none;
}

@media screen and (max-width: $lg) {

    .MenubarDemoMobile,
    .MenubarHomeMobile {
        height: var(--height-header-footer);
        display: flex;
        align-items: center;

        .button-menu {
            color: var(--text-color);

            .pi {
                font-size: 1.5rem !important;
            }
        }

        .logo-page {
            width: 13.5rem;
            height: auto;
        }

        .logo-page-contract {
            height: 50px;
        }

    }


    .sidebar-mobile {

        .item-link {
            color: #212529;
        }

        .p-accordion-content {
            padding: 0 !important;
        }

    }

    .SideBarDemoMobile,
    .SideBarHomeMobile {
        .menu-item .sub-menu{
            right:0 !important;
        }
        .p-panelmenu-panel {
            .p-panelmenu-header:not(.p-highlight):not(.p-disabled)>a:hover {
                background: none;
            }

            .p-panelmenu-header {
                a {
                    background: none;
                    border: none !important;
                    border-radius: 0 !important;
                }
            }

            .p-panelmenu-header>a:focus {
                box-shadow: -1px -1px var(--surface-a), -2px -2px var(--surface-a), -3px -3px var(--surface-a),
                    -4px -4px var(--surface-a), -5px -5px var(--surface-a), -6px -6px var(--surface-a),
                    -7px -7px var(--surface-a), -8px -8px var(--surface-a);
            }

            .p-highlight>a {
                box-shadow: -1px -1px var(--surface-a), -2px -2px var(--surface-a), -3px -3px var(--surface-a),
                    -4px -4px var(--surface-a), -5px -5px var(--surface-a), -6px -6px var(--surface-a),
                    -7px -7px var(--surface-a), -8px -8px var(--surface-a);
                transition: 0.5;
                animation: shadow-pop-tl 0.3s;
            }

            .p-highlight:not(.p-disabled)>a:hover {
                border: none;
                background: none;
            }

            .p-panelmenu-content {
                border: none !important;
                border-radius: 0px;
                border-bottom: thin solid var(--surface-a);
            }

            .p-panelmenu-content {
                padding-left: 2rem;
            }
        }

        .text-white {
            margin-left: 1.4rem;
            color: #fff !important;

            .p-menuitem-text {
                color: #fff !important;
            }
        }
    }
}

@keyframes shadow-pop-tl {
    0% {
        box-shadow: 0 0 var(--surface-a), 0 0 var(--surface-a), 0 0 var(--surface-a), 0 0 var(--surface-a),
            0 0 var(--surface-a), 0 0 var(--surface-a), 0 0 var(--surface-a), 0 0 var(--surface-a);
        transform: translateX(0) translateY(0);
    }

    100% {
        box-shadow: -1px -1px var(--surface-a), -2px -2px var(--surface-a), -3px -3px var(--surface-a),
            -4px -4px var(--surface-a), -5px -5px var(--surface-a), -6px -6px var(--surface-a),
            -7px -7px var(--surface-a), -8px -8px var(--surface-a);
        transform: translateX(8px) translateY(8px);
    }
}
