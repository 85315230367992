
.p-treetable{
    .treetable-responsive-demo .sm-visible {
        display: none;
    }
    .p-treetable-tbody > tr > td .p-treetable-toggler {
        width: auto;
    }
    .p-treetable-thead tr th {
        background-color: var(--surface-a) !important;
        border: solid 1px var(--surface-d) !important;
    }
    .p-treetable-tbody tr td {
        border: solid 1px var(--surface-d) !important;
    }
}

@media screen and (max-width: 40em) {
    .treetable-responsive-demo .sm-invisible {
        display: none;
    }

    .treetable-responsive-demo .sm-visible {
        display: inline;
        margin-right: .5rem;
    }
}
.p-col-d {
    display: table-cell;
}

.p-col-m {
    display: none;
}

@media screen and (max-width: 64em) {
    .p-col-d {
        display: none;
    }

    .p-col-m {
        display: inline-block;
    }
}
