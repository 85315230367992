@media screen and (max-width: 960px) {
    .layout-content {
        margin-left: 0;
        padding-top: 110px;

        .content-section {
            &.introduction {
                flex-direction: column;

                .app-inputstyleswitch {
                    margin-top: 1.5rem;
                }
            }
        }
    }

    .layout-mask {
        background-color: rgba(0, 0, 0, 0.1);

        &.layout-mask-active {
            z-index: 998;
            width: 100%;
            height: 100%;
            position: fixed;
            top: 0;
            left: 0;
            background-color: rgba(0, 0, 0, 0.4);
            transition: background-color 0.5s;
        }
    }

    .layout-config {
        .layout-config-button {
            left: auto;
            right: -52px;
        }

        &.layout-config-active {
            width: 100%;
        }
    }

    .blocked-scroll {
        overflow: hidden;
    }
}


@media screen and (max-width: $md) {
    .layout-news .layout-news-container img.layouts-news-mockup-image {
        width: 95%;
    }
    .p-button:not(.p-button-icon-only) {
        display: flex;
        width: 100%!important;
    }
    .btn-icon-max{
        width: 100%!important;
    }
    .p-splitbutton{
        display: flex;
        width: 100%!important;
    }
    .button-cs .p-button {
        margin-bottom: 0.5rem;
    }

}
