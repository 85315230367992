$stepFs: 18px;
$stepFsMb: 13px;
$stepW: 2.714rem;
$stepWMb: 1.714rem;
.CustomStep {
    // border: 1px solid var(--surface-d);
    // border-radius: 4px;
    // padding: 5px;
    .p-steps {
        .p-steps-item {
            justify-content: flex-start;
            flex-direction: row-reverse;
            align-items: center;
        }
        .p-steps-item:before {
            position: static;
            left: auto;
            top: auto;
            margin-top: 0;
        }

        .p-steps-item .p-menuitem-link {
            flex-direction: row;
            flex: 1 1 auto;
            overflow: visible;
            padding: 0 0.5rem;
        }

        .p-steps-item .p-menuitem-link .p-steps-number {
            // background-color: #9e9d9e;
            // color: #ffffff;
            min-width: $stepW;
            height: $stepW;
            line-height: $stepW;
            font-size: $stepFs !important;
        }
        // .p-steps-item.p-highlight .p-steps-number {
        //     // background-color: var(--primary);
        //     // color: #ffffff;
        // }

        .p-steps-item .p-menuitem-link .p-steps-number {
            border-radius: 50%;
        }
        .p-steps-item .p-menuitem-link .p-steps-title {
            margin: 0;
            padding-left: 0.5rem;
            color: #000;
            font-size: $stepFs !important;
        }
        .p-steps-item:last-child {
            flex-grow: 0;
        }
    }
}

@media screen and (max-width: 640px) {
    .CustomStep {
        .p-steps {
            height: 200px;
            overflow: auto;
            > ul {
                flex-direction: column;
                height: 100%;
                .p-steps-item {
                    flex-direction: column-reverse;
                    align-items: flex-start;
                    justify-content: center;

                    &:before {
                        position: static;
                        left: auto;
                        top: auto;
                        margin-top: 0;
                        border-left: 1px solid var(--surface-d);
                        border-top: 0 none;
                        width: auto;
                        height: 100%;
                        margin-left: 1rem;
                    }

                    .p-menuitem-link {
                        flex-direction: row;
                        overflow: visible;
                        .p-steps-number {
                            font-size: $stepFsMb !important;
                            min-width: $stepWMb;
                            height: $stepWMb;
                            line-height: $stepWMb;
                        }
                        .p-steps-title {
                            margin: 0 0.5rem 0;
                            font-size: $stepFsMb !important;
                        }
                    }

                    &:last-child {
                        flex-grow: 0;

                        .p-menuitem-link {
                            padding: 0;
                        }

                        &:before {
                            display: none;
                        }
                    }
                }
            }
        }
    }

    // .theme-material {
    // for theme switcher on showcase
    .CustomStep {
        .p-steps {
            > ul {
                .p-steps-item {
                    &:before {
                        margin-left: calc(1.714rem / 2);
                    }

                    .p-menuitem-link {
                        padding: 0.5rem 0;
                    }
                }
            }
        }
    }
    // }
}
